/* .form-container {
    max-width: 350px;
    margin: 0 auto;
    border: 1px solid #dcd3d3;
    border-radius: 7px;
    background-image: url("../../assets/images/body-bg.jpg"); 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: white;
  
   
} */

/* Form {
    padding-top: 20px;
    padding-bottom: 20px;
}

.text-muted {
    color: white !important;

}

.login-title {
    color: white;
    text-align: center;
    padding-bottom: 20px;
} */




/* Button {
    background-color: #008cf0;
} */

.container-login {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
  
  .form-container {
    width: 100%;
    padding: 32px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: rgba(0, 0, 255, 0.5); /* Xanh dương đậm hơn */
    backdrop-filter: blur(10px); /* Hiệu ứng mờ */
    background-image: url("../../assets/images/body-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}




.login-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff;
}
  
.mb-3 {
    margin-bottom: 16px;
}
  
.form-control {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
}
  
.form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
  
.form-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
}
  
.text-danger {
    font-size: 12px;
    color: #dc3545;
}
  
.btn-login {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 12px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
}

.btn-login:hover {
    background-color: #0056b3;
}
  

