.manage-authors-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
}
  
.manage-authors-title {
    margin-bottom: 10px;
    color: #eaeaea;

}
  
.manage-authors-add-author-button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 3px;
    margin-top: 10px;
}

/* FORM */
.manage-authors-search-form {
    display: flex;
    flex-direction: row;
    align-items: left;
    border-radius: 5px;
  }
  
  .manage-authors-search-input {
    width: 400px;

  }
  
  .manage-authors-search-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100%;

  }
  
  .manage-authors-search-button {
    background-color: #007bff;
    color: #fff;
    padding: 0px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    margin-left: 10px;
  }
  
  
  
  .author-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-header {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    text-align: left;
    padding: 10px;
  }
  
  .table-data {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .table-row:nth-child(even) {
    background-color: #f2f2f2;
  }
  

  .add-new-author-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Nền mờ màu đen với độ trong suốt */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }



.manage-authors-table {
    color: #eaeaea; /* Màu chữ mặc định sáng hơn */
    margin-top: 15px;
}

.manage-authors-table th, .manage-authors-table tr {
    border: 1px solid #3b3a38; /* Màu viền tối hơn */
    padding: 8px 12px;
    text-align: left;
    background-color: #3b3a38; 

}

.manage-authors-table th {
    border-bottom: 2px solid #4c4b48;
}

.manage-authors-table td {
    border: none;
}

.manage-authors-table tr:hover {
    background-color: #4c4b48; 
    color: #f5f5f5;
    cursor: pointer;
}

/* manageusers-addnewuser-modal */

.manageauthors-addnewauthor-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Nền mờ màu đen với độ trong suốt */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.manageauthors-addnewauthor-modal-content {
  width: 350px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px; /* Khoảng cách giữa các mục */
}

.manageauthors-addnewauthor-modal-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 15px;
  color: #3b3a38 !important;
}

.manageauthors-addnewauthor-modal-content input {
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  transition: border-color 0.3s;

  /* Hiệu ứng khi focus vào input */
  &:focus {
      border-color: #007bff;
  }
}

.manageauthors-addnewauthor-modal-content button {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 16px;
}

/* Nút "Thêm" có màu xanh */
.manageauthors-addnewauthor-modal-content .addnewauthor-modal-save-btn {
  background-color: #007bff;
  color: #ffffff;
}

.addnewauthor-modal-remove-btn {
    background-color: #dc3545;
    color: #ffffff;
}

.manage-authors-table .column-ten {
  width: 600px;
}


.manage-authors-table-get-songs {
  color: #eaeaea; /* Màu chữ mặc định sáng hơn */
  margin-top: 15px;
}

.manage-authors-table-get-songs th, .manage-authors-table-get-songs tr {
  border: 1px solid #3b3a38; /* Màu viền tối hơn */
  padding: 8px 12px;
  text-align: left;
  background-color: #3b3a38; 

}

.manage-authors-table-get-songs th {
  border-bottom: 2px solid #4c4b48;
}

.manage-authors-table-get-songs td {
  border: none;
}

.manage-authors-table-get-songs tr:hover {
  background-color: #4c4b48; 
  color: #f5f5f5;
  cursor: pointer;
}

.manage-authors-table-get-songs .column-ten {
  width: 600px;
}