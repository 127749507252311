.manage-contracts-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
}
  
.manage-contracts-title {
    margin-bottom: 10px;
    color: #eaeaea;

}
  
.manage-contracts-add-contract-button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 3px;
    margin-top: 10px;
}

/* FORM */




.manage-contracts-search-form {
    display: flex;
    flex-direction: row;
    align-items: left;
    border-radius: 5px;
  }
  
  .manage-contracts-search-input {
    width: 270px;

  }
  
  .manage-contracts-search-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100%;

  }
  
  .manage-contracts-search-button {
    background-color: #007bff;
    color: #fff;
    padding: 0px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    margin-left: 10px;
  }

  .manage-contracts-search-form {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Khoảng cách giữa các ô tìm kiếm */
}





.column-date {
    width: 100px;
}

/* TABLE  */
.total-contract {
  color: #eaeaea; /* Màu chữ mặc định sáng hơn */

}
.manage-contracts-table {
  color: #eaeaea; /* Màu chữ mặc định sáng hơn */
  margin-top: 15px;
}

.manage-contracts-table th, .manage-contracts-table tr {
  border: 1px solid #3b3a38; /* Màu viền tối hơn */
  padding: 8px 12px;
  text-align: left;
  background-color: #3b3a38; 

}

.manage-contracts-table th {
  border-bottom: 2px solid #4c4b48;
}

.manage-contracts-table td {
  border: none;
}

.manage-contracts-table tr:hover {
  background-color: #4c4b48; 
  color: #f5f5f5;
  cursor: pointer;
}

.btn-container {
  display: flex;
  gap: 10px; /* Khoảng cách giữa các button */
}

.btn-modify {
  background-color: #e97a1e;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: opacity 0.3s;
  width: 90px;
  height: 40px;
}

.btn-modify:hover {
  background-color: #f19e22;
}


/* ADD NEW CONTRACT POPUP */

.managecontracts-addnewcontract-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7); /* màu nền mờ */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* đảm bảo popup xuất hiện trên cùng */
}




.managecontracts-addnewcontract-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center; /* căn giữa theo chiều ngang */
  flex-direction: column;


}
.managecontracts-addnewcontract-modal-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 15px;
  color: #3b3a38 !important;
}
.input-fields {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px; /* giới hạn chiều rộng tối đa để tránh quá rộng */
}

.input-fields-group {
  display: flex;
  flex-direction: column;
  flex: 1; /* căn chỉnh chiều rộng tự động */
  margin-right: 5px; 
  margin-left: 5px;
}

.input-fields-group:last-child {
  margin-right: 0; /* loại bỏ margin phải của nhóm cuối cùng */
}

.input-fields-group input[type="text"] {
  width: 100%; /* chiếm toàn bộ chiều rộng của nhóm */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%; /* chiếm toàn bộ chiều rộng */
  margin-top: 20px;
}

.addnewcontract-modal-save-btn,
.addnewcontract-modal-remove-btn {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 16px;
  color: #ffffff;
  width: 48%; /* phân chia chiều rộng cho cả hai nút */
}

.addnewcontract-modal-save-btn {
  background-color: #007bff;
  margin-left: 5px;
}

.addnewcontract-modal-remove-btn {
  background-color: #e74c3c;
  margin-right: 5px;
}

.addnewcontract-modal-save-btn:hover,
.addnewcontract-modal-remove-btn:hover {
  opacity: 0.95;
}




/* EDIT CONTRACT POPUP */
.managecontracts-modifycontract-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7); /* màu nền mờ */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* đảm bảo popup xuất hiện trên cùng */
}




.managecontracts-modifycontract-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center; /* căn giữa theo chiều ngang */
  flex-direction: column;


}
.managecontracts-modifycontract-modal-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 15px;
  color: #3b3a38 !important;
}
.input-fields {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px; /* giới hạn chiều rộng tối đa để tránh quá rộng */
}

.input-fields-group {
  display: flex;
  flex-direction: column;
  flex: 1; /* căn chỉnh chiều rộng tự động */
  margin-right: 5px; 
  margin-left: 5px;
}

.input-fields-group:last-child {
  margin-right: 0; /* loại bỏ margin phải của nhóm cuối cùng */
}

.input-fields-group input[type="text"] {
  width: 100%; /* chiếm toàn bộ chiều rộng của nhóm */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%; /* chiếm toàn bộ chiều rộng */
  margin-top: 20px;
}

.modifycontract-modal-save-btn,
.modifycontract-modal-cancel-btn {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 16px;
  color: #ffffff;
  width: 48%; /* phân chia chiều rộng cho cả hai nút */
}

.modifycontract-modal-save-btn {
  background-color: #007bff;
  margin-left: 5px;
}

.modifycontract-modal-cancel-btn {
  background-color: #e74c3c;
  margin-right: 5px;
}

.modifycontract-modal-save-btn:hover,
.modifycontract-modal-cancel-btn:hover {
  opacity: 0.95;
}



/* Loading status  */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #008cf0;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}









