@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #4A4A4A;  /* Màu chữ xám nhẹ */
  font-family: 'Inter', sans-serif;  /* Font chữ hiện đại */
}

.container-user {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  height: 100vh; 


}

.login-logo {
  width: 300px; 
  height: auto; 
}



.sidebar {
  flex-direction: column;
  align-items: center; 
  display: flex;
  justify-content: space-between;

  background-color: #f1f3f4;
}


.dashboard-menu {
  width: 280px;
  padding: 10px;
 
}



.main-content {
  flex: 4;
  padding: 10px;
  position: relative;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.account-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
}

/* Đoạn báo lỗi, nằm ngay bên dưới phần account */
/* .error {
  margin-top: 50px;
  color: red;
  text-align: center;
  font-weight: bold;
} */

/* Nội dung Outlet nằm bên dưới báo lỗi */
.main-content > .outlet {
  margin-top: 20px;
}

form {
  margin-bottom: 1em;
}

input[type="text"],
input[type="submit"] {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1em;
  border-radius: 4px;
}

input[type="text"] {
  border: 1px solid #ddd;
}

input[type="submit"] {
  color: #fff;
  background-color: #008cf0;
  border: none;
  cursor: pointer;
}
input[type="submit"]:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388; 


}
.results {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.result-item {
  border: 1px solid #ddd;
  padding: 1em;
  border-radius: 4px;
}

h2 {
  color: #008cf0;
  margin: 0;
}

.error {
  color: #ff0000;
}

table {
  width: 100%;
  border-collapse: collapse;
  
}

.table-container {
  height: 90vh;
    overflow-y: auto; /* Thanh cuộn dọc xuất hiện khi cần */
  border: 1px solid #ccc; /* Viền cho container */
}



th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Header của bảng */
thead th {
  background-color: #f2f2f2;
  position: sticky; /* Cố định header khi cuộn */
  top: 0;
  z-index: 1;
  top: -3px;
}

thead{
  color: #FFF;
  font-weight: 700;

  white-space: nowrap;
  }


table,
th,
td {
  border: 1px solid #ddd;
  padding: 1em;
  text-align: left;
}

tbody tr:hover {
  background-color: #e0e0e0; 
  cursor: pointer;
}

.author-table-container {
  max-height: 80vh; /* Chiều cao tối đa chiếm 70% màn hình */
  overflow: hidden; 
  border: 1px solid #ddd;
  
}


.author-table {
  width: 100%;
  border-collapse: collapse;

}

.author-table thead th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  border: 1px solid #ddd;
}




.author-table tbody tr:hover {
  background-color: transparent !important;
  height: 100px !important;
}


.tacpham-container {
  height: calc(100vh - 300px); /* Giới hạn chiều cao */
  overflow-y: auto; /* Hiển thị thanh cuộn nếu nội dung vượt quá */
  padding: 10px;
  border: 1px solid #ddd; /* Viền cho container */
  box-sizing: border-box; /* Đảm bảo padding không ảnh hưởng đến kích thước */
}


th {
  background-color: #f2f2f2;
}



th {
  background-color: #008cf0;
  color: white;
  font-size: 0.8rem;
  text-align: center;
  background: -moz-linear-gradient(-45deg, #4facfe 0%, #38ef7d 100%);
  background: -webkit-linear-gradient(-45deg, #4facfe 0%, #38ef7d 100%);
  background: linear-gradient(135deg, #4facfe 0%, #38ef7d 100%);
  
}

td {
  font-size: 0.8rem;
}

.column-stt {
  width: 50px;
}

.column-ten {
  width: 200px;
}

.column-ma {
  width: 100px;
}

.column-tentg {
  width: 200px;
}
.column-tenthat {
  width: 200px;
}


.result {
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 550;
}

.tac-pham{
  margin: 0;
  vertical-align: top ;
}

.ma-code {
  position: relative;
  cursor: pointer;
  text-align: center;
}
.ten-that-tac-gia, .but_danh {
  position: relative;
  cursor: pointer;
}

/* CSS */
.table-data {
  vertical-align: top ;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #008cf0;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Phân biệt mỗi cụm bài hát */
.song-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: relative;
  margin: 10px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.song-group:hover {
  background-color: #f7f7f7;
}

.song-title {
  margin-bottom: 4px;
  width: 100%;
  text-align: left; /* Đảm bảo văn bản được căn lề bên trái */
}


.buttons-wrapper {
  display: flex;
  gap: 5px;
  align-self: flex-end; /* Đưa nút về phía bên phải của .song-group */
}

.btn-detail, .btn-add {
  padding: 5px 10px;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 13px;
}

/* .btn-add, .btn-added {
  width: 70px;  
  white-space: nowrap;  
} */

.btn-add {
  background-color: #007BFF;
}

.btn-detail:hover {
  background-color: #1e7a34;
}

.btn-add:hover {
  background-color: #0056b3;
}

.btn-add, .btn-added {
  width: 70px;  
  white-space: nowrap;  
}

.btn-added {
  font-size: 12px;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;



}

.btn-added, .btn-add:disabled {
  background-color: #C0C0C0; 
  color: white;
  cursor: not-allowed;
}

/* Đảm bảo rằng không có hiệu ứng hover khi nút bị disable */
.btn-added:hover, .btn-add:disabled:hover {
  background-color: #C0C0C0;
}




.logout-button {
  background-color: #ff4b5c;  /* Màu nền của nút */
  color: white;  /* Màu chữ */
  border: none;  /* Bỏ viền */
  padding: 10px 20px;  /* Khoảng cách bên trong nút (top-bottom và left-right) */
  border-radius: 5px;  /* Bo góc cho nút */
  font-weight: bold;  /* Đặt chữ in đậm */
  cursor: pointer;  /* Khi di chuột lên nút sẽ hiện hình dạng tay chỉ */
  transition: background-color 0.3s;  /* Thêm hiệu ứng màu nền khi hover */
  margin-top: 20px;
}

.logout-button:hover {
  background-color: #e4394d;  /* Thay đổi màu nền khi di chuột lên nút */
}

.logout-button:active {
  background-color: #d12a40;  /* Thay đổi màu nền khi nhấn nút */
}

.added-songs-link, .history-songs-link {
  text-decoration: none;  
  color: #2d7bf6;  
  padding: 8px 16px;  
  border: 2px solid #2d7bf6;  
  border-radius: 5px;  
  font-weight: bold;  
  transition: background-color 0.3s, color 0.3s;  
  display: inline-block;  
  text-align: center;  /* Căn giữa nội dung */
  width: 230px;
}

.added-songs-link:hover, .history-songs-link:hover {
  background-color: #2d7bf6;  
  color: white;  
}

.added-songs-link:active, .history-songs-link:active {
  background-color: #1f5bbf;  
}

.history-songs-link {
  margin-top: 20px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.logout-modal-title {
  text-align: center;
  margin-bottom: 10px;
}

/* Styles cho các nút */
.logout-confirm-btn, .logout-cancel-btn {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  font-size: 16px;
  transition: transform 0.2s, box-shadow 0.2s;
  outline: none; /* Loại bỏ đường viền màu xanh khi nhấn vào nút */
}

.logout-confirm-btn {
  background-color: #E74C3C; /* Màu đỏ */
  color: white;
}

.logout-cancel-btn {
  background-color: #3498DB; /* Màu xanh dương */
  color: white;
}

/* Hiệu ứng hover cho các nút */
.logout-confirm-btn:hover {
  transform: scale(1.05); /* Tăng kích thước nút lên 5% khi di chuột vào */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Thêm bóng cho nút */
}

.logout-cancel-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Hiệu ứng khi nhấn vào các nút */
.logout-confirm-btn:active, .logout-cancel-btn:active {
  transform: scale(0.95); /* Giảm kích thước nút xuống 5% khi nhấn vào */
}


/* AuthorPage */
.author-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Đảm bảo rằng nó sẽ chiếm toàn bộ chiều ngang của ô */
}

.tac-pham-container {
  display: flex;
  flex-direction: column; /* sắp xếp theo chiều dọc */
  flex: 1; /* Cho phép nó mở rộng và chiếm tối đa không gian */
}

.tac-pham {
  /* Style cho mỗi bút danh. Bạn có thể thêm padding hoặc margin ở đây nếu cần */
}

.view-works-btn {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-works-btn:hover {
  background-color: #1e7a34;
}

.go-back-button {
  background-color: #4CAF50; /* Màu xanh lá cây */
  border: none;             /* Không viền */
  color: white;             /* Màu chữ trắng */
  padding: 5px 10px;       /* Padding trên dưới và trái phải */
  text-align: center;       /* Căn giữa chữ */
  text-decoration: none;   /* Bỏ gạch chân */
  display: inline-block;   /* Hiển thị inline-block */
  font-size: 15px;         /* Cỡ chữ */
  margin: 4px 2px;         /* Margin trên dưới và trái phải */
  cursor: pointer;         /* Con trỏ chuột dạng tay */
  border-radius: 5px;      /* Bo góc nút */
  transition-duration: 0.4s; 
}

.go-back-button:hover {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-3px);
}








@media screen and (max-width: 768px) {
  .container-user{
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  /* input[type="text"],
input[type="submit"] {
  width: 100% ;
  padding: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1em;
  border-radius: 4px;
}
.input-text {
  width: 100%;
} */

  input {
    max-width: 300px !important;
  }
  .sidebar {
    margin-bottom: 10px;
  }
  
  .main-content {
    font-size: 0.5rem !important;
  }
  td {
    font-size: 0.7rem;
  }
  /* Add styles for the table on mobile */
  .table {
    /* Add styles for the table */
  }
  .column-tacpham{
    width: 230px !important;
  }
  .songs-table {
    /* Add styles for the table displaying songs */
  }

  .authors-table {
    /* Add styles for the table displaying authors */
  }
  .song-group{
    width: 200px;
  }
}

