.checklist-viewhistory-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.checklist-viewhistory-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
}

.close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    float: right;
    margin-bottom: 20px;
    border-radius: 8px;

}

.close-button:hover {
    background-color: #d32f2f;
}

.history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.history-table th, .history-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.history-table th {
    background-color: #f2f2f2;
    color: black;
}

.history-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.history-table tr:hover {
    background-color: #ddd;
}

.btn-viewhistory {
    background-color: #279250;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    width: 90px;
    height: 40px;
}
  
.btn-viewhistory:hover {
    background-color: #34975a;
}




/* ADD MULTIPLE DOCUMENTS */
.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 0.5px solid #aca4a4;
    border-radius: 5px;
}

.tab.active {
    background-color: #007bff;
    color: #fff;
}

.tab-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.upload-container {
    width: 400px;
}

.attachment-file {
    margin-left: 5px;
}

select {
    padding: 12px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    transition: border-color 0.3s;
    margin-bottom: 10px;
}

select:focus {
    border-color: #19191a;
}

