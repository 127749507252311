.addedsongs-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;

}

.addedsongs-content-wrapper {
  display: flex;
  gap: 20px; 
  align-items: flex-start;
  justify-content: space-between; /* Giãn đều giữa bảng và form */

}

.addedsongs-table-container {
  flex: 3;
  max-height: 600px;
  overflow-y: auto; 
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Đặt chiều rộng cho bảng */

}

/* .song-count {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
} */

/* Bảng */
.addedsongs-table {
  width: 100%; /* Bảng chiếm toàn bộ chiều rộng container */
  border-collapse: collapse;
}

.addedsongs-table thead th {
  background-color: #f2f2f2;
  position: sticky;
  top: -2px;
  z-index: 1;
  padding: 10px;
  border: 1px solid #ddd;
}

.addedsongs-table tbody td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.btn-remove {
  padding: 5px 10px;
  background-color: #e53935;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-remove:hover {
  background-color: #b71c1c;
}

/* Form */
.form-container-addedsongspage {
  flex: 1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.custom-form-input,
.custom-form-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-form-button {
  background-color: #1976d2;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-form-button:hover {
  background-color: #115293;
}












/* .table-addedsongs {
    width: 80%; 
    margin: 0 auto; 
    border-collapse: collapse;
    font-size: 10px;
    line-height: 1.5;
  }
  
  .table-addedsongs thead {
    background-color: #f5f5f5;
  }
  
  .table-addedsongs th {
    padding: 12px;
    border-bottom: 2px solid #e0e0e0;
    font-size: 15px;
  }
  
  .table-addedsongs td {
    padding: 12px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }
  
  .table-addedsongs tr:hover {
    background-color: #f0f0f0;
  }
  
  .table-addedsongs tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .btn-remove {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .btn-remove:hover {
    background-color: #d32f2f;
  }
  

  
.action-remove {
    width: 50px;
}

.column-ten-addedsongs {
    width: 500px;
}


.form-container-addedsongspage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 2rem auto;
}

.custom-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.custom-form h3 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 500;
}

.custom-form-input, .custom-form-textarea {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s;
}

.custom-form-textarea {
  height: 100px;
  resize: vertical;
}

.custom-form-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
  margin-top: 0;

 
}

.custom-form-input:focus, .custom-form-textarea:focus {
  border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0,123,255,0.5);
}

.custom-form-input:focus::placeholder, .custom-form-textarea::placeholder  {
  color: #aaa;
} */



.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.confirm-modal-content {
  background-color: #ffffff;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.confirm-modal-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  margin: 0 auto;
}

.confirm-modal-song-list {
  margin-bottom: 20px;
  height: 300px;
  overflow-y: auto;
}

.confirm-modal-song-item {
  margin: 10px 0;
  font-size: 1.1rem;
}

.confirm-modal-submit-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.confirm-modal-submit-btn:hover {
  background-color: #45a049;
}

.confirm-modal-cancel-btn {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;

  cursor: pointer;
  transition: background-color 0.3s;
}

.confirm-modal-cancel-btn:hover {
  background-color: #d32f2f;
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #008cf0;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
