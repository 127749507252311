.admin-dashboard {
    display: flex;
    height: 100vh;
}

.admin-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 270px;
    background-color: #3b3a39;
    color: white;
    padding: 20px 0;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.admin-sidebar .admin-title {
    text-align: center;
    margin-bottom: 30px;
}

.admin-sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0; /* Thêm dòng này để loại bỏ margin mặc định của ul */
}

.admin-sidebar ul li {
    padding: 15px 15px;
    cursor: pointer;
    transition: background 0.3s;
}

.admin-sidebar ul li:hover {
    background-color: #34495e;
}

.full-width-link i {
    margin-right: 10px;
}

.admin-content {
    flex: 1;
    padding: 20px;
    background-color: #252423;
    overflow-y: auto;
}

.full-width-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    padding: 15px 20px;
}

.logout-button {
    align-self: center;
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
    margin: 20px 0; /* Thêm khoảng cách trên và dưới */
    transition: background-color 0.3s;
}

.logout-button:hover {
    background-color: #c0392b;
}
