/* ProfilePage.css */






.history-block {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
}

.history-header, .history-note {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 10px; 
    font-size: 15px;

}

.history-header {
    font-size: 20px;    
    padding: 5px; 


}


.history-note {
    font-style: italic;
}

.history-songs {
    list-style-type: none;
    padding-left: 0;
}

.history-song {
    padding: 5px 0;
}

.profile-history {
    margin-bottom: 50px;

}



.profile-history .history-table {
    margin: 0 auto; /* Đặt bảng ở giữa trang */
    border-collapse: collapse;
    font-size: 10px;
    line-height: 1.5;
}

.profile-history .history-table, .profile-history th, .profile-history td {
    border: 1px solid #ddd;
}

.profile-history th, .profile-history td {
    padding: 8px 12px;
    text-align: left;
}

.profile-history th {
    background-color: #008cf0;
    text-align: center;

}

.history-section-header {
    width: 80%; /* Giống như chiều rộng của table */
    margin: 0 auto; /* Để căn giữa giống như table */
    padding-bottom: 15px; /* Khoảng cách dưới giữa header và bảng */
}

.history-section h3 {
    margin-top: 0; /* Loại bỏ khoảng cách trên của h2 nếu có */
}

.history-section p {
    margin-bottom: 0; /* Loại bỏ khoảng cách dưới của p nếu có */
}
.history-section {
    margin-bottom: 15px;  /* Tăng khoảng cách giữa các phần tử history-section */
}

.column-nhac, .column-loi {
    width: 230px;
}


.history-section h3 {
    cursor: pointer;
}

.history-section h3:hover {
    background-color: #f7f7f7;
}

.history-header i.open {
    transform: rotate(90deg);
}

.history-header i {
    margin-right: 10px;  
    transition: transform 0.3s ease; 
}

.history-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;  /* Đảm bảo nội dung bên trong không hiển thị khi max-height là 0 */
    transition: opacity 0.4s, max-height 0.4s; 
}

.history-open {
    opacity: 1;
    max-height: 2000px;  
}

.content-container {
    overflow-y: visible; 
}

/* profilePage.css */


.table-and-button-container {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.history-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 10px;
    line-height: 1.5;
    table-layout: fixed; /* Đảm bảo các cột phân chia đều */
    margin-top: 0 !important;


}

.history-table thead th {
    top: -5px;

    text-align: center !important;
    color: #f7f7f7;
}

.history-table-container {
    max-height: 600px; 
    overflow-y: auto; 
    border: 1px solid #ddd; 
  }
  
  /* Tiêu đề của bảng */
  .history-table thead th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  /* Nội dung bảng */
  .history-table tbody td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

.adjustment-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    margin-left: auto; 
    transition: all 0.4s;
}

.adjustment-button:hover {
    background-color: #0056b3; /* Một màu xanh dương sậm hơn */
}


.content-container .search-input {
    width: 80% ;             /* Đặt độ rộng là 80% */
    padding: 10px 15px;
    margin: 15px auto;     /* Đặt margin left và right là tự động để căn giữa */
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s ease;
    display: block;
    /* Thêm hiệu ứng khi focus vào input */
   
}

.content-container .search-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0,123,255,0.5);
}
.content-container .search-input::placeholder  {
    color: #aaa;
}