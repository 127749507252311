.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    position: relative;
  }
/* Nút đóng popup */
/* Nút đóng popup */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 36px; /* Kích thước lớn cho dấu X */
  font-weight: bold; /* Đậm hơn */
  color: #555; /* Màu xám */
  cursor: pointer;
  transition: color 0.3s, transform 0.2s; /* Hiệu ứng hover */
  line-height: 1; /* Đảm bảo icon không bị kéo dài */
}

/* Hiệu ứng hover */
.popup-close:hover,
.popup-close:focus {
  color: #e53935; /* Đổi màu đỏ khi hover */
  transform: scale(1.3); /* Phóng to nhẹ khi hover */
}

/* Loại bỏ outline khi focus */
.popup-close:focus {
  outline: none;
}



  