.manage-users-container {
    padding: 20px;
}

.manage-users-container h2 {
    color: #eaeaea;
} 
.manage-users-table {
    color: #eaeaea; /* Màu chữ mặc định sáng hơn */
    margin-top: 15px;
}

.manage-users-table th, .manage-users-table tr {
    border: 1px solid #3b3a38; /* Màu viền tối hơn */
    padding: 8px 12px;
    text-align: left;
    background-color: #3b3a38; 

}

.manage-users-table th {
    border-bottom: 2px solid #4c4b48;
}


.manage-users-table td {
    border: none;
}
.manage-users-table tr:hover {
    background-color: #4c4b48; 
    color: #f5f5f5;
    cursor: pointer;
}

.delete-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 3px;

}

.delete-button:hover {
    background-color: #c0392b;
}

.toggle-status-button {
    background-color: #3498db; /* Màu nền của nút */
    color: #fff; /* Màu chữ trên nút */
    border: none; /* Loại bỏ viền nút */
    padding: 5px 10px; /* Kích thước nút */
    cursor: pointer; /* Biểu tượng con trỏ khi rê chuột qua nút */
    border-radius: 3px; /* Bo góc nút */
    transition: background-color 0.3s; /* Hiệu ứng khi rê chuột qua nút */
  
    /* Thêm một ô bóng bên dưới nút khi rê chuột qua */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    margin-left: 10px;
  }
  
.toggle-status-button:hover {
    background-color: #2980b9; /* Màu nền khi rê chuột qua nút */
}


.add-user-button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 3px;
    margin-top: 10px;
}

.add-user-button:hover {
    background-color: #27ae60;
}




.manageusers-addnewuser-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Nền mờ màu đen với độ trong suốt */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.manageusers-addnewuser-modal-content {
    width: 350px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px; /* Khoảng cách giữa các mục */
}

.manageusers-addnewuser-modal-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 15px;
    color: #3b3a38 !important;
}

.manageusers-addnewuser-modal-content input {
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    transition: border-color 0.3s;

}



.manageusers-addnewuser-modal-content button {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 16px;
}

/* Nút "Thêm" có màu xanh */
.manageusers-addnewuser-modal-content .addnewsong-modal-save-btn {
    background-color: #007bff;
    color: #ffffff;
}

/* Nút "Hủy" có màu đỏ */
.manageusers-addnewuser-modal-content .addnewuser-modal-remove-btn {
    background-color: #dc3545;
    color: #ffffff;
}

/* Hiệu ứng khi hover lên các nút */
.manageusers-addnewuser-modal-content button:hover {
    opacity: 0.9;
}

.confirm-delete-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7); /* màu nền mờ */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* đảm bảo popup xuất hiện trên cùng */
}

.confirm-delete-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.confirm-delete-modal-confirm-btn,
.confirm-delete-modal-cancel-button {
    /* Cơ bản */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    /* Màu sắc và hiệu ứng */
    background-color: #2D3748;  /* màu nền mặc định */
    color: #FFF;
}

.confirm-delete-modal-confirm-btn:hover,
.confirm-delete-modal-cancel-button:hover {
    background-color: #4A5568;  /* màu nền khi di chuột qua */
}

.confirm-delete-modal-confirm-btn {
    background-color: #E53E3E;  /* màu nền mặc định cho nút xác nhận */
}

.confirm-delete-modal-confirm-btn:hover {
    background-color: #C53030;  /* màu nền khi di chuột qua nút xác nhận */
}

.confirm-delete-modal-title {
    color: #2D3748 !important;
}


.processing-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.processing-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.status-green-dot {
    width: 10px; /* Đặt kích thước của chấm xanh */
    height: 10px;
    background-color: rgb(92, 228, 92); /* Màu xanh lá */
    border-radius: 50%; /* Để làm cho nó tròn */
    display: inline-block; /* Hiển thị inline để nó nằm trong cùng một hàng với văn bản */
}

.status-red-dot {
    width: 10px; /* Đặt kích thước của chấm đỏ */
    height: 10px;
    background-color: red; /* Màu đỏ */
    border-radius: 50%; /* Để làm cho nó tròn */
    display: inline-block; /* Hiển thị inline để nó nằm trong cùng một hàng với văn bản */
}


.addnewuser-modal-save-btn {
    background-color: #007bff;
  color: #ffffff;
}